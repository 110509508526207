import "../../../assets/styles/global.css"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import { css } from "@emotion/react"
import Subscribe from "./Subscribe"
import styled from "@emotion/styled"

const EbookComponent = ({ typeCall = true, EbookId = 2, h1, h2, h5 ,isOnEbookPage=false }) => {
  const data = useStaticQuery(graphql`
        {
     ebook:allMdx{
            edges {
            node {
            frontmatter {
                id
                slug
                ebook_image {
                  relativePath
                  childImageSharp {
                    fluid {
                      tracedSVG
                      srcWebp
                      srcSetWebp
                      originalImg
                      originalName
                    }
                  }
                }                
                title_1
                title_2
                accroche
                document
                {
                  publicURL
                }            
              }
            }
          }
        }
      }    
  `)
  const ebookData = data.ebook.edges.find(
    edge => edge.node.frontmatter.id === EbookId
  )

    const ebook = ebookData.node.frontmatter
  return (
    <ContentWrapper css={typeCall ? styleinsite : stylemodal}>

      {!h1 || !h2 || !h5 && (
        <div className="title">
          {!h1 &&
          <h1 dangerouslySetInnerHTML={{ __html: h1 }} />
          }
          {!h2 &&
          <h2 dangerouslySetInnerHTML={{ __html: h2 }} />
          }
          {!h5 &&
          <h5 dangerouslySetInnerHTML={{ __html: h5 }} />
          }
        </div>
      )}

      <div className="ebookframe">
        <div className="wrapper">
          <div className="one">
            <Img
              placeholderStyle={{
                width: "100%",
                display: "block",
                objectFit: "contain",
                position: "unset"
              }}
              fluid={ebook.ebook_image.childImageSharp.fluid}
              alt="ebook"
            />
          </div>
          <div className="two">
            {typeCall ?
              <>
                <h3 className="title-1">[FREE] {ebook.title_1}</h3>
                <h1 className="title-2">
                  {ebook.title_2}
                </h1>
              </>
              :
              <h3 className="title-1">BEFORE YOU GO:</h3>
            }
            <h1 className="accroche">{ebook.accroche}</h1>
            <Subscribe isOnEbookPage={isOnEbookPage} currentEbook={ebookData.node} isInModal={!typeCall} />
          </div>
        </div>
      </div>
    </ContentWrapper>
  )
}

const ContentWrapper = styled.div`
  width: 80%;
  @media (max-width: 1580px) {
    width: 100% !important;

  }
`


export default EbookComponent

const styleinsite = css`

  .title {
    color: white;
    text-align: center;
  }

  .ebookframe {
    width: 75%;
    border-radius: 5px;
    background-color: white;
    margin: auto;
    padding: 25px 50px;
  }

  .header {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 40px;
  }

  .title-1 {
    font-size: 2em;
    color: #133c9e;
    text-align: left;
    margin: 0 auto auto 0;
  }

  .title-2 {
    display: inline-block;
    font-size: 2em;
    color: #133c9e;
    margin-top: 0;
    text-align: left;
  }

  .checkmark-svg {
    width: 30px;
    height: 30px;
    margin-left: 8px;
  }

  .wrapper {
    display: flex;
    height: 100%;
  }

  .title-wrapper {
    display: grid;
    margin: 50px auto auto auto;
    justify-content: center;
    max-width: 1325px;
  }

  .one {
    align-content: center;
    width: 50%;
    overflow: hidden;

    .gatsby-image-wrapper {
      margin: 6%;
      min-width: 90%;
      min-height: 90%;
      max-width: 100%;
      max-height: 100%;
    }
  }

  .one {
    align-content: center;
    padding: 30px;
    width: 50%;
    overflow: hidden;

    .gatsby-image-wrapper {
      margin: 6%;
      min-width: 90%;
      min-height: 90%;
      max-width: 100%;
      max-height: 100%;
    }
  }

  .two {
    max-width: 500px;
    width: 50%;
    align-content: center;
    padding: 30px;
    margin: auto;

    h1 {
      margin-top: 8px;
      margin-bottom: 37px;
    }
  }

  .accroche {
    font-weight: 500;
    font-size: 1.688em;
  }

  .description_1 {
    font-size: 1.375rem;
    margin-top: 0;

    p {
      margin: 0;
    }
  }

  .Subcribe_component {
    margin-top: 200px
  }

  .description_2 {
    font-size: 1em;
    font-weight: 500;
    margin-top: 28px;

    p {
      margin-bottom: 0;
      margin-top: 5px;
    }

    p img {
      max-width: 58%;
    }
  }

  @media (max-width: 1260px) {
    .ebookframe {
      width: 85%;
      margin-top: 8vh;
      padding: 10px 0px;
    }
  }

  @media (max-width: 1110px) {
    .ebookframe {
      width: 93%;
      margin-top: 8vh;
      padding: 10px 0px;
    }
  }

  @media screen and (max-width: 1024px) {
    .two {
      h1 {
        margin-bottom: 20px;
      }
    }

    .ebookframe {
      width: 99%;
      margin-top: 8vh;
      padding: 10px 0px;
    }
  }
  @media screen and (max-width: 900px) {
    .wrapper {
      display: block;
    }


    .one {
      width: 100%;
      padding: 0px;
    }

    .title-1, .title-2 {
      text-align: center;
    }

    .checkmark-svg {
      width: 20px;
      height: 20px;
    }

    .two {
      text-align: center;
      padding: 0;
      margin: auto;
      width: 85%;

      h1 {
        margin-bottom: 2px;
      }

      #download {
        margin: 0;
        padding: 0 0 0 10px;
      }
    }
  }
  @media screen and (max-width: 768px) {

    .title-1 {
      font-size: 1.2rem;
      margin-top: 0;
      text-align: center;
    }

    .title-2 {
      font-size: 1.2rem;
      color: #133c9e;
      margin: auto 0;
      text-align: center;
    }

    .accroche {
      font-size: 1.1rem;
    }

    .ebookframe {
      width: 85%;
      margin-top: 8vh;
      padding: 10px 0px;
    }
  }
`

const stylemodal = css`

  .one {
    width: 50%;

    .gatsby-image-wrapper {
      margin-top: 5%;
      width: 90%;
      height: 90%;
    }
  }

  .ebookframe {
    width: 100%;
    border-radius: 7px;
    background-color: white;
    margin: auto;
    padding: 50px 50px;
  }

  .header {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 40px;
  }

  .title-1 {
    font-size: 2em;
    color: #133c9e;
    font-weight: bold;
    text-align: left;
    margin: 4.375rem auto auto 0;
  }

  .title-2 {
    display: inline-block;
    font-size: 2em;
    color: #133c9e;
    text-align: left;
  }

  .wrapper {
    display: flex;
    height: 100%;
  }

  .title-wrapper {
    display: grid;
    margin: 50px auto auto auto;
    justify-content: center;
    max-width: 1200px;
  }


  .two {
    max-width: 500px;
    width: 50%;
    align-content: center;

    h1 {
      margin-top: 8px;
      margin-bottom: 37px;
    }
  }

  .accroche {
    font-weight: 500;
    font-size: 1.688em;
  }

  .description_1 {
    font-size: 1.375rem;
    margin-top: 0;

    p {
      margin: 0;
    }
  }

  .Subcribe_component {
    margin-top: 100px
  }

  .description_2 {
    font-size: 1rem;
    font-weight: 500;
    margin-top: 28px;

    p {
      margin-bottom: 0;
      margin-top: 5px;
    }

    p img {
      max-width: 58%;
    }
  }

  @media screen and (max-width: 768px) {
    .wrapper {
      display: block;
    }


    .title-1 {
      font-size: 1.2rem;
      margin-top: 0;
      text-align: center;
    }

    .checkmark-svg {
      width: 20px;
      height: 20px;
    }

    .title-2 {
      font-size: 1.2rem;
      color: #133c9e;
      margin: auto 10px;
      text-align: left;
    }

    .accroche {
      font-size: 1.1rem;
    }

    .two {
      text-align: center;
      margin: auto;
      width: 100% !important;

      h1 {
        margin-bottom: 15px !important;
      }
    }

    .one {
      width: 100% !important;
    }

    .ebookframe {
      padding: 6vw 13vw !important
    }

    .gatsby-image-wrapper {
      margin: auto auto auto -8vw !important
    }

    .gatsby-image-wrapper img {
      width: 135% !important;
    }
  }


`
