import { Link } from "gatsby"
import React from "react"
// import Rgpd from "./rgpd"
import styled from "@emotion/styled"

const Footer = ({ location, isFullpage = true }) => {
  return (
    <FooterWrapper
      isFullpage={isFullpage}
      >
      {/* <Rgpd location={location} /> */}
      <FooterCTN className="gutter">
        <p>
          Focus DataScience, Tour CIT Montparnasse, 3 rue de l’arrivée, 75015
          Paris, France
        </p>
        &nbsp;&nbsp;
        <Link to="/mentions-legales">Legal notice</Link>
      </FooterCTN>
    </FooterWrapper>
  )
}
export default Footer


const FooterWrapper = styled.div`
  bottom: 0;
  width: 100%;
  z-index: 1;

  ${props => props.isFullpage && `
    bottom: 2vh !important;
    position: absolute;
    left: 0;
    @media (max-width: 733px) {
      bottom: 0 !important;
      position: absolute !important;
    }
  `};

  @media (max-width: 733px) {
    position: relative !important;
  }
  
  @media (max-width: 900px) {
    bottom: 0 !important;
  }
`


const FooterCTN = styled.footer`
  background-color: #070724;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fafafb;

  a {
    color: #fafafb;
  }

  @media (max-width: 733px) {

    display: block;
    text-align: center;
    height: auto;
    font-size: 12px;

    p {
      font-size: 12px;
    }
  }

`

