import React, {useEffect, useRef} from "react"
import {useForm} from "react-hook-form"
import {css} from "@emotion/react"
import {LinkedinInsight} from "../../Tools/Linkedin"
import {navigate} from "gatsby";
import {setCurrentBook} from "../../../tools/LocalStorage";

const Subscribe = ({isOnEbookPage = true, isInModal = false,currentEbook}) => {
    useEffect(()=>{
        setCurrentBook(currentEbook)
    })

    let btnRef = useRef()
    const { handleSubmit} = useForm()
    const CONVERSION_ID = process.env.GATSBY_LINKEDIN_CONVERSION_EBOOK_ID
    const formSubmit = (data) => {

        navigate("/hang-on/")
    }

    return (
        <div css={SubscribeStyles}>
            <div className="subscribe_container">
                <form onSubmit={handleSubmit(formSubmit)}>
                    <div
                        className={!isOnEbookPage && isInModal ? " emailBox component modal" : !isOnEbookPage ? " emailBox component" : "emailBox"}>

                        <button ref={btnRef} id="download"
                                type="submit">   {!isOnEbookPage ? "View Details " : " DOWNLOAD "} >

                            {CONVERSION_ID &&
                                <LinkedinInsight CONVERSION_ID={CONVERSION_ID}/>
                            }

                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Subscribe

const SubscribeStyles = css`
  .subscribe_container {
    margin-top: 35px;
  }

  .check-emoji-component {
    position: relative;
    top: 33px;
    left: 196px;
  }

  .emailBox {
    display: flex;
    align-items: center;

  }

  .emailBox.component {
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 10px;
  }

  .component + #download {
    width: 125px;
  }

  input:not([hidden]) {
    display: flex;
    width: 13rem;
    height: 40px;
    padding-left: 10px;
    border: 2px solid #133C9E;
    border-radius: 10px;

    &:focus {
      outline: none;
    }
  }

  .check-emoji {
    margin-left: 10px

  }

  button {
    margin-top: 10px;
    padding-left: 15px;
    width: 14rem;
    height: 46px;
    background-color: #133C9E;
    color: white;
    text-align: left;
    font-weight: 600;
    cursor: pointer;
    outline: none;
    border: none;
    border-radius: 10px;
    box-shadow: 3px 3px 8px rgb(0 0 0 / 15%);
    display: inline-block;
  }

  .component button {
    width: 13rem;
  }

  .infos {
    margin-top: 5px;
    display: flex;
    flex-direction: row;

    label {
      font-size: 12px;
      opacity: 0.7;
    }

    input {
      max-width: 35px;
      height: 18px;
    }
  }

  .wrap-checkbox {
    display: flex;
    flex-direction: column;
    margin-top: 8px;
  }

  @media screen and (max-width: 969px) {
    .check-emoji-component {
      left: 49px !important;
    }
  }


  @media screen and (max-width: 1024px) {

    .check-emoji-component {
      left: 104px;
    }

    .emailBox.component input {
      width: 7rem;
      margin: auto;
    }

    .component + #download {
      width: 7rem;
      padding-left: 10px;
    }

    .check-emoji {
      position: absolute;
      right: 10%;
      margin-top: 20px;
    }

  }
  @media screen and (max-width: 768px) {
    .subscribe_container {
      margin-top: 15px !important;
    }

    .check-emoji-component {
      left: 47px !important;
    }

    .emailBox, #download {
      margin-left: 10vw;
    }

    .emailBox.component input {
      width: 28.8vw !important;
      margin-left: 0 !important;
    }

    .infos label {
      text-align: left;
    }

    .component + #download {
      width: 30.8vw !important;
      margin-left: 0 !important;
    }

    .emailBox.component {
      margin-right: 0 !important;
      margin-bottom: 5px;
      width: 33.5vw !important;
      margin-left: 0 !important;
      display: inline-block !important;
    }

    .emailBox {
      display: block !important;
    }

    .modal.emailBox {
      display: inline-block !important;
    }

    .modal.emailBox.component input {
      width: 28.8vw !important;
      margin-left: 0 !important;
    }

    .modal.component + #download {
      width: 30vw !important;
      margin-left: 0 !important;
    }

    .modal.emailBox.component {
      margin-right: 25px !important;
      margin-bottom: 5px;
      width: 27.5vw !important;
      margin-left: 0 !important;
    }

    .modal.emailBox {
      display: inline-block !important;
    }

  }
`



