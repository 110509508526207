import React from "react"
import { css } from "@emotion/react"
import { Link,useStaticQuery, graphql } from "gatsby"

const TopBar = ({Border}) => {
  const data = useStaticQuery(graphql`
  {ebook:allMdx(filter:{frontmatter:{id:{eq:1}}}) {
    edges {
      node {
        frontmatter {
          id
          slug
          title_1
          title_2
        }
      }
    }
  }
}
`)
  return (
    <div css={TopBarStyles} >
      <div id="topbar"  style={{
        borderBottom : Border
      }}>

        <div id="topbar-content-ctn" className="gutter">
          <Link to={`/free-guides/${data.ebook.edges[0].node.frontmatter.slug}`} className="freeGuide">[FREE TODAY] {data.ebook.edges[0].node.frontmatter.title_1} {data.ebook.edges[0].node.frontmatter.title_2}<button>DOWNLOAD</button></Link>
        </div>
      </div>
    </div>
  )
}

export default TopBar

const TopBarStyles = css`
#topbar {
  background-color: #070724;
  display:flex;
}

#topbar-content-ctn {
  height: 2.16034566rem;
  max-width: 39.1262602rem;
  margin: auto;
  display: flex;
  align-items: center;
}

#topbar-content-ctn a {
  text-decoration: none;
  color: #797b8a;
  font-weight: 300;
}

#topbar-content-ctn a:hover {
  color: #9095a3;
}

@media (max-width: 1023px) {
  display: none;
}

button{
  border-radius: 3rem;
  background-color: transparent;
  color:  #9095a3;
  font-size: 13px;
  margin-left:25px;
  padding:3px 10px 3px 10px;
  border:1px solid  #9095a3;
}
#topbar-content-ctn>a.freeGuide{
  color: #9095a3;
  vertical-align:middle;
}

#topbar-content-ctn>a.freeGuide:hover,#topbar-content-ctn>a.freeGuide:hover>button{
  color:#b9bdca;
}
`

