
// Function to set current ebook to local storage
export const setCurrentBook = (currentEbook) => {
  if (typeof window !== "undefined") {
    localStorage.clear()
    localStorage.setItem("currentEbook", JSON.stringify(currentEbook))
  }
}

// Function to retrieve current ebook id from localStorage
export const getEbookId = () => {
  if (typeof window !== "undefined") {
    return localStorage.getItem("currentEbook")
      ? JSON.parse(localStorage.getItem("currentEbook")).frontmatter.id
      : undefined
  }
}

export const getCurrentEbookFromLocalStorage = () => {
  if (typeof window !== "undefined") {
    return localStorage.getItem("currentEbook")
        ? JSON.parse(localStorage.getItem("currentEbook")).frontmatter
        : undefined
  }
}
