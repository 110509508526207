import styled from "@emotion/styled"

export const ModalWrapper = styled.div`
  z-index: 9999;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
`
export const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
`

export const ModalContent = styled.div`
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  transition: all 400ms cubic-bezier(0.4, 0, 0.2, 1);

  @media (max-width: 768px) {
    left: 43vw !important;
    width: 65% !important;
  }
`
export const CloseModal = styled.span`
  font-size: 30px;
  position: relative;
  float: right;
  padding: 15px 0 0 0;
  right: 1vw;
  font-weight: bold;
  opacity: 0.3;
  cursor: pointer;
  @media (max-width: 768px) {
    right: -11vw !important;
  }
`


