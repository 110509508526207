import React, { useState, forwardRef } from "react"
import Portal from "./Portal"
import EbookComponent from "../Content/Ebooks/EbookComponent"
import { CloseModal, ModalBackdrop, ModalContent, ModalWrapper } from "./StyledModal"

const Modal = forwardRef((props, ref) => {

  const isBrowser = typeof window !== "undefined"
  const [display, setDisplay] = useState(false)

  const setModalFlag = (bool) => {
    if (typeof window !== "undefined") {
      localStorage.clear()
      localStorage.setItem("setmodalFlag", JSON.stringify(bool))
    }
  }

  const getModalFlag = () => {
    if (typeof window !== "undefined") {
      return localStorage.getItem("setmodalFlag")
        ? JSON.parse(localStorage.getItem("setmodalFlag"))
        : undefined
    }
  }

  if (isBrowser) {
    const handleKey = () => {
      const close = e => {
        if (e.keyCode === 27) {
          handleClose()
        }
      }
      window.addEventListener("keydown", close)
      return () => window.removeEventListener("keydown", close)
    }
    handleKey()

    let timeoutInMiliseconds = 120000
    let timeoutId

    function startTimer() {
      timeoutId = window.setTimeout(doInactive, timeoutInMiliseconds)
    }

    function resetTimer() {
      window.clearTimeout(timeoutId)
      startTimer()
    }

    function doInactive() {
      handleOpen(true)
    }

    window.addEventListener("beforeunload", function(e) {
      setModalFlag(true)
      return undefined
    })

    function setupTimers() {
      document.addEventListener("mousemove", resetTimer, false)
      document.addEventListener("mousedown", resetTimer, false)
      document.addEventListener("keypress", resetTimer, false)
      document.addEventListener("touchmove", resetTimer, false)
      startTimer()
    }

    document.addEventListener("mouseleave", function(e) {
      handleOpen()
    })
    const canDisplayOnPage = () => {
      return window.location.href.indexOf("free-guide") < 0 && window.location.href.indexOf("meeting") < 0 && window.location.href.indexOf("contact-us") < 0 && window.location.href.indexOf("about-us") < 0
    }
    setupTimers()

    const handleOpen = (inactive = false) => {
      if (canDisplayOnPage()) {
        if (inactive) {
          if (getModalFlag() !== false) {
            setDisplay(true)
            document.body.style.overflow = "hidden"
            let audio = document.getElementById("notification")
            audio.volume = 0.2
            audio.play()
          }
        } else {
          if (getModalFlag() !== false) {
            setDisplay(true)
            document.body.style.overflow = "hidden"
          }
        }
      }
    }
  }

  const handleClose = () => {
    document.body.style.overflow = "unset"
    setModalFlag(false)
    setDisplay(false)
  }

  if (display) {
    return (
      <div>
        <Portal>
          <audio id="notification" hidden="hidden"
                 src="https://fds-cdn-website-dev.azureedge.net/uploads/freesound_notification_4.wav" />
          <ModalWrapper>
            <ModalBackdrop />
            <ModalContent>
              <CloseModal onClick={handleClose}>X</CloseModal>
              <EbookComponent typeCall={false} isOnEbookPage/>{props.children}
            </ModalContent>
          </ModalWrapper>
        </Portal>
      </div>
    )
  }
  return null
})

export default Modal

