import React, { useRef, useState } from "react"
import Modal from "../Modal/Modal"
import { Link } from "gatsby"
import { css } from "@emotion/react"

const Menu = ({ menuColor,scrollToTop }) => {
  const [servicesMenu, setServicesMenu] = useState(false)
  const [aboutMenu, setAboutMenu] = useState(false)
  const [resourcesMenu, setResourcesMenu] = useState(false)
  const [navVisible, setNavVisible] = useState(false)
  const modalRef1 = useRef()
  return (
    <div css={menuColor === "white" ? WhiteMenuStyles : DarkMenuStyles}>
      <Modal ref={modalRef1}/>
      <nav
        style={{
          animation: navVisible ? "slideDown .3s forwards" : "none",
        }}
      >
        <Link onClick={(event)=>scrollToTop(event)} to="/">Home</Link>
        <div
          role="menu"
          onClick={() => {
            setServicesMenu(!servicesMenu)
            if (aboutMenu) {
              setAboutMenu(!aboutMenu)
            }
            if (resourcesMenu) {
              setResourcesMenu(!resourcesMenu)
            }
          }}
          className="menu-item-ctn"
        >
          <div>
            <span>Services</span>
            <svg className="arrow-icon">
              <use href="/icons-sprite-map.svg#down-arrow-icon" />
            </svg>
          </div>
          {servicesMenu && (
            <div className="submenu">
              <Link onClick={(event)=>scrollToTop(event)}  to="/data-management-oversight/">Oversight</Link>
              <Link onClick={(event)=>scrollToTop(event)}  to="/data-management/"> Data Management</Link>
              <Link onClick={(event)=>scrollToTop(event)}  to="/biostatistics/">Biostatistics</Link>
              <Link onClick={(event)=>scrollToTop(event)}  to="/quality-assurance/">Quality Assurance</Link>
            </div>
          )}
        </div>
        <div
          role="menu"
          onClick={() => {
            setResourcesMenu(!resourcesMenu)
            if (servicesMenu) {
              setServicesMenu(!servicesMenu)
            }
            if (aboutMenu) {
              setAboutMenu(!aboutMenu)
            }
          }}
          className="menu-item-ctn"
        >
          <div>
            <span>Resources</span>
            <svg className="arrow-icon">
              <use href="/icons-sprite-map.svg#down-arrow-icon" />
            </svg>
          </div>
          {resourcesMenu && (
            <div className="submenu">
              <Link onClick={(event)=>scrollToTop(event)}  to="/free-guides/">Free-Guides</Link>
            </div>
          )}
        </div>
        <div
          role="menu"
          onClick={() => {
            setAboutMenu(!aboutMenu)
            if (servicesMenu) {
              setServicesMenu(!servicesMenu)
            }
            if (resourcesMenu) {
              setResourcesMenu(!resourcesMenu)
            }
          }}
          className="menu-item-ctn"
        >
          <div>
            <span>About our CRO</span>
            <svg className="arrow-icon">
              <use href="/icons-sprite-map.svg#down-arrow-icon" />
            </svg>
          </div>
          {aboutMenu && (
            <div className="submenu">
              <Link onClick={(event)=>scrollToTop(event)} to="/about-us/"> About us</Link>
              <Link onClick={(event)=>scrollToTop(event)}   to="/contact-us/">Contact us</Link>
              <Link onClick={(event)=>scrollToTop(event)}   to="/references/">Projects Completed</Link>
            </div>
          )}
        </div>
        <Link onClick={(event)=>scrollToTop(event)}  to="/references/">References</Link>
        <Link onClick={(event)=>scrollToTop(event)} to="/StartHere/">Start Here</Link>
      </nav>
      <svg onClick={() => setNavVisible(!navVisible)} id="hamburger-menu-icon">
        <use href="/icons-sprite-map.svg#hamburger-menu-icon" />
      </svg>
    </div>
  )
}

export default Menu

const WhiteMenuStyles = css`
  nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 39rem;
  }
  li{
    margin-top:-34px;
    margin-left:50px;
    list-style:none;
    display:block;
  }

  nav a {
    text-decoration: none;
    color: #0c0c3d;
  }

  nav > a {
    padding-bottom: 0.1125rem;
  }

  nav > a:hover {
    border-bottom: 0.0625rem solid #0c0c3d;
  }

  nav span {
    padding-bottom: 0.3125rem;
  }

  nav span:hover {
    border-bottom: 0.0625rem solid #0c0c3d;
  }

  nav > a:last-child {
    border: 0.096rem solid #0c0c3d;
    padding: 0.48rem 1.15218435rem;
    border-radius: 0.24rem;
    margin-bottom: 0.3125rem;
    transition: background-color 0.3s, color 0.3s;
  }

  nav > a:last-child:hover {
    background-color: #0c0c3d;
    color: white;
  }

  .submenu {
    position: absolute;
    top: 1.5rem;
    background-color: white;
    padding: 0.4rem 0.625rem;
    border-left: 0.1875rem solid #8c8a94;
    border-radius: 0.1875rem;
    min-width: 12rem;
    // display: none;
  }

  .submenu > a {
    display: block;
    font-weight: 300;
    margin-bottom: 0.3125rem;
  }

  .submenu > a:last-child {
    margin-bottom: 0;
  }

  .submenu > a:hover {
    font-weight: 400;
  }

  .menu-item-ctn {
    position: relative;
    cursor: pointer;
    padding-bottom: 0.0125rem!important;
    color: #0c0c3d;
  }

  .arrow-icon {
    width: 0.625rem;
    height: 0.625rem;
    fill: #0c0c3d;
    margin-left: 3px;
  }

  #hamburger-menu-icon {
    width: 1.2rem;
    height: 1.2rem;
    fill: #0c0c3d;
    display: none;
  }

  @media (max-width: 1023px) {
    nav {
      position: absolute;
      top: 3.421875rem;
      left: 0;
      display: block;
      width: 100%;
      z-index: -1;
      transform: translateY(-100%);
    }

    nav > a {
      display: block;
      padding: 1.2rem;
      border-bottom: 0.0625rem solid grey;
      background-color: #e8eeff;
      text-align: center;
      transition: background-color 0.3s, color 0.3s;
    }

    nav > a:hover {
      background-color: #0c0c3d;
      color: white;
    }

    nav > a:last-child {
      padding: 1.2rem;
      border-top: 0;
      border-left: 0;
      border-right: 0;
      margin-bottom: 0;
      border-radius: 0;
      border-color: grey;
    }

    nav span:hover {
      border-bottom: initial;
    }

    .menu-item-ctn {
      padding: 0;
      border-bottom: 0.0625rem solid grey;
    }

    .menu-item-ctn > div:first-of-type {
      padding: 1.2rem;
      background-color: #e8eeff;
      text-align: center;
      transition: background-color 0.3s, color 0.3s;
    }

    .menu-item-ctn > div:first-of-type:hover {
      background-color: #0c0c3d;
      color: white;
    }

    .submenu {
      position: static;
      padding: 0;
      border: 0;
    }

    .submenu > a {
      margin-bottom: 0;
      padding: 1.2rem;
      background-color: #56607c;
      color: white;
      text-align: center;
    }

    #hamburger-menu-icon {
      display: block !important;
      cursor: pointer;
    }
  }
`

const DarkMenuStyles = css`

li{
  margin-top:-34px;
  margin-left:50px;
  list-style:none;
  display:block;
}
  nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 39rem;
  }
  nav a {
    text-decoration: none;
    color: #e2e3e6;
  }

  nav > a {
    padding-bottom: 0.1125rem;
  }

  nav > a:hover {
    border-bottom: 0.0625rem solid #e2e3e6;
  }

  nav span {
    padding-bottom: 0.3125rem;
  }

  nav span:hover {
    border-bottom: 0.0625rem solid #e2e3e6;
  }

  nav > a:last-child {
    border: 0.096rem solid #e2e3e6;
    padding: 0.48rem 1.15218435rem;
    border-radius: 0.24rem;
    margin-bottom: 0.3125rem;
    transition: background-color 0.3s, color 0.3s;
  }

  nav > a:last-child:hover {
    background-color: #e2e3e6;
    color: #0c0b29;
  }

  .submenu {
    position: absolute;
    top: 1.5rem;
    background-color: transparent;
    padding: 0.4rem 0.625rem;
    border-left: 0.1875rem solid #8c8a94;
    border-radius: 0.1875rem;
    min-width: 12rem;
    // display: none;
  }

  .submenu > a {
    display: block;
    font-weight: 300;
    margin-bottom: 0.3125rem;
  }

  .submenu > a:last-child {
    margin-bottom: 0;
  }

  .submenu a:hover {
    font-weight: 400;
  }

  .menu-item-ctn {
    position: relative;
    cursor: pointer;
    padding-bottom: 0.0125rem!important;
    color: #e2e3e6;
  }

  .arrow-icon {
    width: 0.625rem;
    height: 0.625rem;
    fill: #e2e3e6;
    margin-left: 3px;
  }

  #hamburger-menu-icon {
    width: 1.2rem;
    height: 1.2rem;
    fill: #e2e3e6;
    display: none;
  }

  @media (max-width: 1023px) {
    nav {
      position: absolute;
      top: 3.421875rem;
      left: 0;
      display: block;
      width: 100%;
      z-index: -1;
      transform: translateY(-100%);
    }

    nav > a {
      display: block;
      padding: 1.2rem;
      border-bottom: 0.0625rem solid grey;
      background-color: #191532;
      text-align: center;
      transition: background-color 0.3s, color 0.3s;
    }

    nav > a:hover {
      background-color: #e2e3e6;
      color: #0c0b29;
    }

    nav > a:last-child {
      padding: 1.2rem;
      border-top: 0;
      border-left: 0;
      border-right: 0;
      margin-bottom: 0;
      border-radius: 0;
      border-color: grey;
    }

    nav span:hover {
      border-bottom: initial;
    }

    .menu-item-ctn {
      padding: 0;
      border-bottom: 0.0625rem solid grey;
    }

    .menu-item-ctn > div:first-of-type {
      padding: 1.2rem;
      background-color: #191532;
      text-align: center;
      transition: background-color 0.3s, color 0.3s;
    }

    .menu-item-ctn > div:first-of-type:hover {
      background-color: #e2e3e6;
      color: #0c0b29;
    }

    .submenu {
      position: static;
      padding: 0;
      border: 0;
    }

    .submenu > a {
      margin-bottom: 0;
      padding: 1.2rem;
      background-color: #3c3951;
      text-align: center;
    }

    #hamburger-menu-icon {
      display: block!important;
      cursor: pointer;
    }
  }
`
