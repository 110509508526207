import React from 'react'

export function LinkedinInsight({CONVERSION_ID}) {
  const PID = process.env.GATSBY_LINKEDIN_PID
  const SRC = `https://px.ads.linkedin.com/collect/?pid=${PID}&conversionId=${CONVERSION_ID}&fmt=gif`
  return (
    <img
      height="1"
      width="1"
      style={{display:"none"}}
      alt=""
      src={SRC}
    />
  )
}